// @codekit-append "../node_modules/jquery/dist/jquery.min.js";
// @codekit-append "easings.min.js";
// @codekit-append "scrolloverflow.min.js";
// @codekit-append "fullpage.scrollHorizontally.min.js";
// @codekit-append "fullpage.extensions.min.js";
// @codekit-append "../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-append "../bower_components/magnific-popup/dist/jquery.magnific-popup.min.js";
// @codekit-append "jquery.parallax-scroll.js";
// @codekit-append "jquery.inview.min.js";



jQuery(document).ready(function() {
  setTimeout(function(){
    jQuery("div.loader").fadeOut();
  }, 1000);

  var wHeight = jQuery(window).height() / 2;

  jQuery('.slide--leistungen').bind('mousewheel DOMMouseScroll touchstart', function (e) {
    jQuery("div.element").each(function() {
      if(jQuery(this).offset().top <= wHeight){
        jQuery("div.element").removeClass("highlight");
        jQuery(this).addClass("highlight");
      }
    });

    if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
        jQuery("header").removeClass("header--scrolled");
    } else {
        jQuery("header").addClass("header--scrolled");
    }



  });

  jQuery('.slide--work').bind('mousewheel DOMMouseScroll touchstart', function (e) {

    if(jQuery(".slide--work-content").offset().top <= 60){
      jQuery(".logo").removeClass("white");
      jQuery('.menuicon').removeClass('white');
    } else {
      jQuery(".logo").addClass("white");
      jQuery('.menuicon').addClass('white');
    }

    if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
        jQuery("header").removeClass("header--scrolled");
    } else {
        jQuery("header").addClass("header--scrolled");
    }
  });

  jQuery('.slide--jobs').bind('mousewheel DOMMouseScroll touchstart', function (e) {
    if(jQuery(".slide--jobs-content").offset().top <= 60){
      jQuery(".logo").removeClass("white");
      jQuery('.menuicon').removeClass('white');
    } else {
      jQuery(".logo").addClass("white");
      jQuery('.menuicon').addClass('white');
    }

    if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
        jQuery("header").removeClass("header--scrolled");
    } else {
        jQuery("header").addClass("header--scrolled");
    }
  });

  jQuery('.slide--team').bind('mousewheel DOMMouseScroll touchstart', function (e) {
    if(jQuery(".slide--team-intro").offset().top < -40) {
      //jQuery(".subheader-team").fadeOut();
      //jQuery("header").removeClass("line");
    } else {
      ///jQuery(".subheader-team").fadeIn();
      //jQuery("header").addClass("line");
    }

    if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
        jQuery("header").removeClass("header--scrolled");
    } else {
        jQuery("header").addClass("header--scrolled");
    }
  });

  jQuery('img.anim, img.anim2').on('inview', function(event, isInView) {
    if (isInView) {
      jQuery(this).delay(700).addClass("active");
    } else {
    }
  });

  jQuery('body.fp-viewing-home-3 .slide--team-content img.img-detail-1').on('inview', function(event, isInView) {
    if (isInView) {
      jQuery(".subheader-team").fadeOut();
    } else {
      jQuery(".subheader-team").fadeIn();
    }
  });


  jQuery('#wrapper').fullpage({
    anchors: ['home', 'team', 'dolore'],
    licenseKey: 'A69BA88D-632E4DC4-B694B973-08B8004C',
    slidesNavigation: false,
    controlArrows: false,
    scrollOverflow: true,
    scrollOverflowOptions: {
      momentum: false,
      tap: true
    },
    verticalCentered: false,
    scrollHorizontally: true,
    easingcss3: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
    scrollHorizontallyKey: 'c2NobGVpY2gtZW5nLmRlX1N2SWMyTnliMnhzU0c5eWFYcHZiblJoYkd4NTUzag==',


    afterSlideLoad: function(section, origin, destination, direction){

      console.log("Origin: "+origin.index);
      console.log("Destin: "+destination.index);
      console.log("Direct: "+direction);

      jQuery('.logo').removeClass('white');
      jQuery('.menuicon').removeClass('white');

      if(origin.index == 3 && destination.index == 4) {
        jQuery('.logo').addClass('white');
        jQuery('.menuicon').addClass('white');
      }
      if(origin.index == 4 && destination.index == 5) {
        jQuery('.logo').addClass('white');
        jQuery('.menuicon').addClass('white');
      }

      jQuery("header").removeClass("header--scrolled");

    },

		onSlideLeave: function(anchorLink, index, slideIndex, direction){
      console.log("Leave" + slideIndex.index);
      console.log("Direc" + direction);


      if(slideIndex.index == 0) {
        jQuery(".subheader-front").fadeIn();
      } else {
        jQuery(".subheader-front").fadeOut();
      }
      if(slideIndex.index == 1 || slideIndex.index == 2 || slideIndex.index == 4 || slideIndex.index == 5) {
        jQuery("header").removeClass("line");
      } else {
        jQuery("header").addClass("line");
      }

      if(slideIndex.index == 3) {
        jQuery('div.leistungen--content-top').removeClass('active');
        jQuery(".subheader-team").fadeIn();
      } else {
        jQuery(".subheader-team").fadeOut();
      }

      if(slideIndex.index == 2) {
        jQuery('div.leistungen--content-top').addClass('active');
      }

      if(slideIndex.index == 1) {
        jQuery('div.slide--unternehmen-vision div.box').addClass('active');
        jQuery('div.leistungen--content-top').removeClass('active');
      }

      if(slideIndex.index == 4) {
        jQuery('div.slide--work-intro').addClass('active');
      }

		}
  });

  var navOffset = jQuery(".menuicon").offset().left;
  var navWidth  = 0;
  if(jQuery(window).width() > 780) {
    navWidth  = navOffset + 640;
  } else if(jQuery(window).width() > 667) {
    navWidth  = navOffset + 400;
  } else {
    navWidth  = navOffset + 240;
  }


  //console.log("W Width: "+jQuery(window).width());
  //console.log("N Width: "+navWidth);

/*

  jQuery("nav").css("padding-left", navOffset);
  jQuery("nav").css("left", "-"+navWidth+"px");

  jQuery(document).on("click",".menuicon, img.menuicon_close, nav a",function() {
    jQuery("div.outer").toggleClass("opaq");
    jQuery("nav").toggleClass("nav-active");
    if(jQuery("nav").hasClass("nav-active")) {
      jQuery('div.outer').css("-webkit-transform", "translateX("+navWidth+"px)");
      jQuery('div.outer').css("translate3d", navOffset+"px, 0, 0");
    } else {
      jQuery('div.outer').css("-webkit-transform", "translateX(0px)");
      jQuery('div.outer').css("translate3d", "0px, 0, 0");
    }
  });
*/
jQuery("nav").css("padding-left", navOffset);
jQuery("nav").css("left", "-"+navWidth+"px");
jQuery(document).on("click",".menuicon, img.menuicon_close, nav a",function() {
  jQuery("div.outer").toggleClass("opaq");
  jQuery("nav").toggleClass("nav-active");
});


  jQuery(document).on("click","[data-anchor]",function() {
    var anchor = jQuery(this).attr("data-anchor");

    setTimeout(function(){

    }, 1000);

  });

  jQuery(document).on("click","span.person",function() {
    var person = jQuery(this).attr("data-show-person");
    jQuery('div.team--detail').fadeOut();
    jQuery('div.team--detail[data-person="'+person+'"]').delay(500).fadeIn();
  });



  jQuery(document).on("click","div.team--detail img.icon--close",function() {
    jQuery('div.team--detail').fadeOut();
  });

  jQuery(document).on("click","img.slider--cases-next",function() {
    jQuery('.slider--cases').slick('slickNext');
  });

  jQuery('.slider--cases').on('afterChange', function(event, slick, currentSlide, nextSlide){
    jQuery("span.case-nav").removeClass("active");
    jQuery('span.case-nav[data-show="'+currentSlide+'"]').addClass("active");
  });

  jQuery('.slider--cases').slick({
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
   fade: true
  });

  jQuery(document).on("click","span.case-nav",function() {
    var show = jQuery(this).attr("data-show");
    jQuery("span.case-nav").removeClass("active");
    jQuery(this).addClass("active");
    var slider = jQuery( '.slider--cases' );
    slider[0].slick.slickGoTo(parseInt(show));
  });

  jQuery(document).on("click","img.loadmore",function() {
    jQuery("div.impulse--more").slideToggle();
  });

  jQuery("div.impulse--more").hide();

  jQuery('.open-popup-link-imprint').magnificPopup({
    type:'inline',
    midClick: true
  });

  jQuery('.open-popup-link-contact').magnificPopup({
    type:'inline',
    midClick: true
  });

  jQuery('.open-popup-link-datenschutz').magnificPopup({
    type:'inline',
    midClick: true
  });

  jQuery('.open-popup-link-iframe').magnificPopup({
    type:'iframe',
    midClick: true
  });


  jQuery("a[href='#impressum']").bind( "tap", tap_imprint );
  jQuery("a[href='#contact']").bind( "tap", tap_contact );
  jQuery("a[href='#datenschutz']").bind( "tap", tap_datenschutz );
  jQuery("a.open-popup-link-iframe").bind( "tap", tap_stelle );



  function tap_stelle( event ){
    jQuery('.open-popup-link-iframe').magnificPopup('open');
  }
  function tap_contact( event ){
    jQuery('.open-popup-link-contact').magnificPopup('open');
  }
  function tap_imprint( event ){
    jQuery('.open-popup-link-imprint').magnificPopup('open');
  }

  function tap_datenschutz( event ){
    jQuery('.open-popup-link-datenschutz').magnificPopup('open');
  }

});
